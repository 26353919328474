const GRAPHQL_WS = 'graphql-ws';
// NOTE: This protocol is deprecated and will be removed soon.
/**
 * @deprecated
 */
const GRAPHQL_SUBSCRIPTIONS = 'graphql-subscriptions';

export {
  GRAPHQL_WS,
  GRAPHQL_SUBSCRIPTIONS,
};
